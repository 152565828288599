
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CarousellDot',
  props: {
    itemKey: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showWide: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  emits: ['dotClicked'],
  data () {
    return {
      heigth: 0
    };
  },
  computed: {
    classContainer (): string {
      if (this.showWide) {
        return 'dot_container--wide';
      }

      return '';
    },
    classDot (): string {
      if (this.active) {
        return 'dot--active';
      }

      return '';
    }
  },
  methods: {
    clicked (): void {
      this.$emit('dotClicked', this.itemKey);
    }
  }
});
