
import { defineComponent, PropType } from 'vue';
import { StartAboutUsBoxData } from '@/api/ApiStartseite';
import { formatPurifyText } from '@/helper/textFormatting';

export default defineComponent({
  name: 'StartseiteUeberUns',
  props: {
    aboutUs: {
      type: Array as PropType<Array<StartAboutUsBoxData>>,
      required: true
    }
  },
  methods: {
    formatText (text: string): string {
      return formatPurifyText(text);
    }
  }
});

