// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { churchtoolsClient, activateLogging } from '@churchtools/churchtools-client';
import { baseUrlChurchtools } from '@/other/config';
import Termin from '@/data_models/Termin';
import axios, { AxiosResponse } from 'axios';

export interface CalendarInfo {
  id: number;
  name: string;
  nameTranslated: string;
  sortKey: number
  color: string; // Farbe als Hex
  campusId: number
}

export interface AppointmentDto {
  base: {
    id: number;
    caption: string; // Titel
    information: string // Termin-Infos
    note: string; // ggf auch leerer String
    startDate: string; // Start-Zeitpunkt (des ersten Termins bei einer Serie) als ISO-String "2023-11-11T09:00:00Z"
    endDate: string; // End-Zeitpunkt (des ersten Termins bei einer Serie) als ISO-String
    allDay: boolean // Ganztätig
    calendar: CalendarInfo;
    isInternal: boolean,
    link: string, // Link oder leerer String
    version: number,
    additionals: Array<unknown>,
    address: unknown | null,
    exceptions: Array<unknown>,
    image: unknown | null,
    meta: unknown,
    onBehalfOfPid: unknown | null,
    repeatId: number,
    repeatFrequency: unknown | null,
    repeatOption: unknown | null
    repeatUntil: unknown | null,
    signup: unknown | null,
  },
  calculated: {
    startDate: string, // Start-Zeitpunkt des eigentlichen Termins als ISO-String "2023-11-11T09:00:00Z"
    endDate: string, // End-Zeitpunkt des eigentlichen Termins als ISO-String "2023-11-11T09:00:00Z"
  },
}

export async function getAppointments (
  config: {
    baseUrl: string;
    authToken: string;
    startDate: Date;
    endDate: Date;
    calendarIds: Array<number>
  }
): Promise<AppointmentDto[] | null> {
  const startDate = config.startDate.toISOString().split('T')[0];
  const endDate = config.endDate.toISOString().split('T')[0];

  const calendarIdsStrings = config.calendarIds.map(
    (id: number) => `calendar_ids[]=${id}`
  );

  const calIdString = '&' + calendarIdsStrings.join('&');

  const requestUrl = `${config.baseUrl}calendars/appointments?from=${startDate}&to=${endDate}${calIdString}&login_token=${config.authToken}`;

  const axiosResponse: AxiosResponse = await axios.get(requestUrl);

  return axiosResponse.data.data;
}

// export async function getCal (
//   config: {
//     daysInfoFuture: number;
//   }
// ): Promise<any[] | null> {
//   activateLogging();
//   churchtoolsClient.setBaseUrl(baseUrlChurchtools);
//
//   // post('/login', {
//   //   username: '',
//   //   password: ''
//   // }).then(result => {
//   //   if (result.status === 'success') {
//   //     console.log('Login successful!');
//   //     return clientA.get('/whoami');
//   //   }
//   // }).then(result => {
//   //   console.log('User A: ', result.data);
//   // });
//
//   console.log('Who am I:');
//   churchtoolsClient.get('/whoami').then((whoAmI: unknown) => {
//     console.dir(whoAmI);
//   }).catch((error: unknown) => {
//     console.error(error);
//   });
//
//   // Kategorien der Kalender:
//   // Gottesdienst 2
//   // Veranstaltungen 1
//   // Generation plus 27
//   // Jugend + EC 3
//   // Pfadfinder PFC 24
//   // Alpha-Kurs 21
//   // Buchcafé 38
//   // Kleingruppen 57
//   // Bibelkreis 42
//   // Gebetskreis 45
//
//   const params = {
//     category_ids: [2, 1, 27, 3, 24, 21, 38, 57, 42, 45],
//     from: 0,
//     to: config.daysInfoFuture
//   };
//
//   const result = await churchtoolsClient.oldApi('churchcal/ajax', 'getCalendarEvents', params);
//   console.log('Get Calendar');
//   console.log(result);
//
//   // // eslint-disable-next-line no-restricted-syntax,no-shadow
//   // for (const event of axiosResponse.data.data as event[]) {
//   //   const t = new Termin(
//   //     event.id,
//   //     event.name,
//   //     new Date(event.startDate),
//   //     new Date(event.endDate),
//   //     null,
//   //     event.calendar.title
//   //   );
//   //   termine.push(t);
//   // }
//
//   return [];
// }
