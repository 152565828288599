import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-025d515c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "startseiteVorstellung" }
const _hoisted_2 = { class: "text-primary" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", { innerHTML: _ctx.textFormatted }, null, 8, _hoisted_3)
  ]))
}