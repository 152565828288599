
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Tabs',
  // emits: ['update:activeTab', 'tabSelected'],
  props: {
    items: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    activeTab: {
      type: String,
      required: false,
      default: () => null
    }
  },
  emits: ['update:activeTab', 'tabSelected'],
  data () {
    return {
      selectedTab: this.activeTab ?? this.items[0]
    };
  },
  watch: {
    activeTab: {
      handler (newSelectedTab: string) {
        if (newSelectedTab !== this.selectedTab) {
          this.selectedTab = newSelectedTab;
        }
      }
    }
  },
  methods: {
    selectTab (tabName: string): void {
      this.selectedTab = tabName;
      this.$emit('update:activeTab', this.selectedTab);
      this.$emit('tabSelected', this.selectedTab);
    }
  }
});
