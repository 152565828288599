import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07906a34"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "pageLoadError",
  class: "text-primary text-bold text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoadWaiting = _resolveComponent("PageLoadWaiting")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: _normalizeClass(["loader bg-dark", _ctx.classes])
    }, [
      (_ctx.state === 'loading' || _ctx.state === 'fadeAway')
        ? (_openBlock(), _createBlock(_component_PageLoadWaiting, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.state === 'noConnection' || _ctx.state === 'error')
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.errorText), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.state === 'loadingDone')
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true)
  ], 64))
}