
import { defineComponent, PropType } from 'vue';
import PageLoadWaiting from '@/components/Loading/PageLoadWaiting.vue';
import { loadData, LoadingRequestedData, LoadingState } from '@/api/Loading';

export default defineComponent({
  name: 'PageLoader',
  components: {
    PageLoadWaiting
  },
  data () {
    return {
      state: 'loading' as LoadingState
    };
  },
  props: {
    loadingDataTargets: {
      type: Object as PropType<LoadingRequestedData>,
      required: true
    }
  },
  computed: {
    classes (): string {
      if (this.state === 'loadingDone') {
        return 'fadeOut';
      }

      return '';
    },
    errorText ():string {
      switch (this.state) {
        case 'noConnection':
          return 'Wir können die Website gerade leider nicht erreichen';
        case 'error':
          return 'Da ist was schief gegangen. Vielleicht hilf neu laden?';
        case 'loadingDone':
        case 'loading':
      }
      return '';
    }
  },
  created () {
    // eslint-disable-next-line no-return-assign
    loadData(this.loadingDataTargets).then((r) => this.state = r);
  }
});
