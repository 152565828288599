
export interface config {
  moveCallbacks: boolean,
  resistance: number
}

const defaultConfig: config = {
  moveCallbacks: false,
  resistance: 0
};

export type SwipeEventsMove = 'moveup' | 'movedown' | 'moveleft' | 'moveright';
export type SwipeEventsSwipe = 'swipeup' | 'swipedown' | 'swipeleft' | 'swiperight';
export type SwipeEvents = SwipeEventsMove | SwipeEventsSwipe;

type SwipeEventListener = {event: SwipeEvents, callback: () => unknown};

export class Swipe {
  private options: config;
  private target: Node;

  private callbacks: Array<SwipeEventListener> = [];
  private swipeEvent = {
    touchStart: null as null | TouchEvent,
    touchMove: null as null | TouchEvent,
    touchEnd: null as null | TouchEvent
  };

  private event: SwipeEvents | null = null ;
  private xDown: number | null = null
  private yDown: number | null = null

  constructor (target: Node = document, options: config = defaultConfig) {
    this.options = { ...defaultConfig, ...options };
    this.target = target;
  }

  public init (): void {
    this.addListeners();
  }

  public destroy (): void {
    this.removeListeners();
    this.callbacks = [];
  }

  public on (event: SwipeEvents, callback: () => unknown): Swipe {
    this.callbacks.push({
      event: event,
      callback: callback
    });

    return this;
  }

  public off (event: SwipeEvents, callback: () => unknown): Swipe {
    this.callbacks = this.callbacks.filter(
      (e: SwipeEventListener) => !(callback === e.callback && event === e.event)
    );

    return this;
  }

  private addListeners () {
    this.target.addEventListener('touchstart', this.handleTouchStart);
    this.target.addEventListener('touchmove', this.handleTouchMove);
    this.target.addEventListener('touchend', this.handleTouchEnd);
  }

  private removeListeners () {
    this.target.removeEventListener('touchstart', this.handleTouchStart);
    this.target.removeEventListener('touchmove', this.handleTouchMove);
    this.target.removeEventListener('touchend', this.handleTouchEnd);
  }

  private handleTouchStart: EventListener = (e: Event): void => {
    const event: TouchEvent | null = (e instanceof TouchEvent) ? e : null;
    if (event === null) return;

    const [firstTouch] = event.touches;
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;

    this.swipeEvent.touchStart = event;
  }

  private handleTouchMove: EventListener = (e: Event): void => {
    if (!this.xDown || !this.yDown) return;

    const event: TouchEvent | null = (e instanceof TouchEvent) ? e : null;
    if (event === null) return;

    this.swipeEvent.touchMove = event;

    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY;

    const xDiff = this.xDown! - xUp;
    const yDiff = this.yDown! - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > this.options.resistance) {
        this.chooseEventCallback('moveleft', 'swipeleft');
      } else if (xDiff < this.options.resistance * -1) {
        this.chooseEventCallback('moveright', 'swiperight');
      }
    } else if (yDiff > this.options.resistance) {
      this.chooseEventCallback('moveup', 'swipeup');
    } else if (yDiff < this.options.resistance * -1) {
      this.chooseEventCallback('movedown', 'swipedown');
    }

    if (this.options.moveCallbacks) {
      this.xDown = xUp;
      this.yDown = yUp;
    }
  }

  private handleTouchEnd: EventListener = (e: Event): void => {
    const event: TouchEvent | null = (e instanceof TouchEvent) ? e : null;
    if (event === null) return;

    this.swipeEvent.touchEnd = event;

    if (this.event != null) {
      this.triggerCallback(this.event);
    }

    this.xDown = null;
    this.yDown = null;
    this.swipeEvent.touchStart = null;
    this.swipeEvent.touchMove = null;
    this.swipeEvent.touchEnd = null;
  }

  private chooseEventCallback (optionalEvent:SwipeEventsMove, defaultEvent: SwipeEventsSwipe) {
    if (this.options.moveCallbacks && this.hasCallback(optionalEvent)) {
      this.event = optionalEvent;
      this.triggerCallback(optionalEvent);

      setTimeout(() => {
        this.event = defaultEvent;
      });
    } else {
      this.event = defaultEvent;
    }
  }

  private hasCallback (event = ''): boolean {
    return !!this.callbacks.find((e) => e.event === event);
  }

  private triggerCallback (event: SwipeEvents) {
    const currentCallbacks = this.callbacks.filter((e: SwipeEventListener) => e.event === event);
    currentCallbacks.forEach((e) => e.callback());

    setTimeout(() => {
      this.event = null;
    });
  }
}
