import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1914cda9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile" }
const _hoisted_2 = { class: "tile-content" }
const _hoisted_3 = { class: "tile-title" }
const _hoisted_4 = { class: "tile-content" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.neuigkeit.titel), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", {
          class: "tile-subtitle text-gray text-small",
          innerHTML: _ctx.newsText
        }, null, 8, _hoisted_5),
        (_ctx.neuigkeit.description != null && _ctx.neuigkeit.description.trim().length > 0 && !_ctx.showFulltext)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary btn-sm text-dark",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showNewsDetail && _ctx.showNewsDetail(...args)))
            }, " Mehr... "))
          : _createCommentVNode("", true),
        (_ctx.showFulltext)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-primary btn-sm text-dark",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeNewsDetail && _ctx.closeNewsDetail(...args)))
            }, " Zurück "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}