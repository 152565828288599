import { loadStartseiteData } from '@/api/ApiStartseite';
import { BaseData } from '@/data_models/BaseData';
import { loadPageBaseData } from '@/api/ApiBaseDate';
import { loadMainSlidesData } from '@/api/ApiMainSlides';

export type LoadingState = 'loading' | 'noConnection' | 'error' | 'loadingDone';
export type LoadingResult = 'noConnection' | 'error' | 'loadingDone';
export type LoadingRequestedDataTargets = 'BaseData'| 'StartData' | 'MainSlides' | 'ImpressumData' | 'DatenschutzData';
export type LoadingRequestedData = Array<{target: LoadingRequestedDataTargets, ids: Array<string> | null}>;

export async function loadData (requestedData: LoadingRequestedData): Promise<LoadingResult> {
  // const loadingTargetStates = [] as Array<Record<LoadingRequestedDataTargets, boolean>>;
  // const loadingTargetStates = [] as Array<{loadingTarget: LoadingRequestedDataTargets, loadingState: boolean}>;
  const loadingTargetStates = {} as Record<LoadingRequestedDataTargets, LoadingState>;
  let loadingResult: LoadingResult = 'loadingDone';

  for (const loadingTarget of requestedData) {
    // loadingTargetStates.push({ loadingTarget: false, loadingState: false });
    loadingTargetStates[loadingTarget.target] = 'loading';

    let result: LoadingResult | null = null;
    switch (loadingTarget.target) {
      case 'BaseData':
        result = await loadBaseData();
        break;
      case 'StartData':
        result = await loadStartData();
        break;
      case 'MainSlides':
        result = await loadMainSlides();
        break;
      case 'ImpressumData':
        result = await loadImpressumData();
        break;
      case 'DatenschutzData':
        result = await loadDatenschutzData();
        break;
    }

    if (result !== 'loadingDone') {
      loadingResult = result ?? 'error';
    }

    loadingTargetStates[loadingTarget.target] = result ?? 'error';

    console.log(`Loading of ${loadingTarget.target} done with result ${result}`);
  }

  return loadingResult;
}

const MAX_RETRIES = 3;

async function loadStartData (retryCount = 0): Promise<LoadingResult> {
  // TODO: If data in local Storage and the data is not old, use this data and do a reload in the background

  if (retryCount >= MAX_RETRIES) {
    return 'error';
  }

  // Load the data
  try {
    const data = await loadStartseiteData();
    BaseData.startData = data;
    // TODO: Write the data to local storage

    return 'loadingDone';
  } catch (e) {
    console.warn('Error loading start data', e);

    return 'error';
    // TODO: ??? Retry to load the data on fail
    // if (triedTimes < 3) {
    //   setTimeout(() => { this.loadData(triedTimes++); }, 2000);
    // }
  }
}

async function loadMainSlides (retryCount = 0): Promise<LoadingResult> {
  // TODO: If data in local Storage and the data is not old, use this data and do a reload in the background

  if (retryCount >= MAX_RETRIES) {
    return 'error';
  }

  // Load the data
  try {
    const data = await loadMainSlidesData();
    BaseData.mainSlides = data;
    // TODO: Write the data to local storage

    return 'loadingDone';
  } catch (e) {
    console.warn('Error loading start data', e);

    return 'error';
    // TODO: ??? Retry to load the data on fail
    // if (triedTimes < 3) {
    //   setTimeout(() => { this.loadData(triedTimes++); }, 2000);
    // }
  }
}

async function loadBaseData (retryCount = 0):Promise<LoadingResult> {
  // TODO: If data in local Storage and the data is not old, use this data and do a reload in the background

  if (retryCount >= MAX_RETRIES) {
    return 'error';
  }

  // Load the data
  try {
    // TODO: Echte Base Data Laden
    // const data = await loadPageBaseData();
    const data = { test: 'loading works' };
    BaseData.pageBaseData = data;
    // TODO: Write the data to local storage

    return 'loadingDone';
  } catch (e) {
    console.warn('Error loading start data', e);

    return 'error';
    // TODO: ??? Retry to load the data on fail
    // if (triedTimes < 3) {
    //   setTimeout(() => { this.loadData(triedTimes++); }, 2000);
    // }
  }
}

async function loadImpressumData ():Promise<LoadingResult> {
  console.warn('Not implemented: Loading Impressum');
  return 'error';
}

async function loadDatenschutzData ():Promise<LoadingResult> {
  console.warn('Not implemented: Loading Datenschutz');
  return 'error';
}
