export default class Termin {
  readonly terminId: number;

  readonly title: string;

  readonly begin: Date;

  readonly end: Date | null;

  readonly descriptions: string[] = [];

  // für die Gruppe besser ein gruppen-Objekt verwenden
  readonly calendar: string | null;

  constructor (id: number, title: string, begin: Date, end: Date|null = null,
    description: string[]|null = null, calendar: string|null = null) {
    this.terminId = id;
    this.title = title;
    this.begin = begin;
    this.end = end;
    this.calendar = calendar;
  }
}
