import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tab" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab) => {
      return (_openBlock(), _createElementBlock("li", {
        key: tab,
        class: _normalizeClass([_ctx.selectedTab === tab? 'active' : '', "tab-item"]),
        onClick: ($event: any) => (_ctx.selectTab(tab))
      }, [
        _createElementVNode("a", {
          class: _normalizeClass(_ctx.selectedTab === tab? 'active' : '')
        }, _toDisplayString(tab), 3)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}