
import { defineComponent, PropType } from 'vue';
import { DirectusImageObject } from '@/api/DirectusDTOs';

export default defineComponent({
  name: 'StartseiteHeadCarousellGreeter',
  props: {
    greeterImage: {
      type: Object as PropType<DirectusImageObject>,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['to-the-dates', 'img-loaded'],
  computed: {
    classes (): string {
      if (this.isActive) { return 'carousell-greeter--active'; }

      return '';
    }
  },
  methods: {
    imgLoaded (): void {
      // TODO: Remove log
      console.log('Greeter Image Loaded');
      this.$emit('img-loaded', 'greeter');
    },
    linkDatesClicked () {
      this.$emit('to-the-dates');
    }
  }
});
