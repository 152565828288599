/* eslint-disable camelcase */
import { client } from '@/api/Directus';
import { DirectusImageObject } from '@/api/DirectusDTOs';

export interface GroupData {
  id: number,
  status: 'published',
  sort: number | null,
  // owner: number,
  // modified_by: number,
  // modified_on: string,
  group_name: string,
  group_slogan: string,
  group_icon: DirectusImageObject | null,
  group_header_image: DirectusImageObject,
  group_short_desc: string,
  group_meeting_when: string | null,
  group_where:
    {
      lat: number
      lng: number
    } | null,
  group_where_address: string | null
}

export async function loadGroups (): Promise<Array<GroupData> | null> {
  try {
    const response = await client.getItems<GroupData[]>(
      'gruppen',
      {
        fields: ['*', 'group_icon.data.full_url', 'group_header_image.data.full_url'],
        sort: 'sort'
      }
      // {
      //   filter: {
      //     status: { eq: 'published' }
      //   }
      // }
    );
    return response.data;
  } catch (e) {
    console.warn('Error when fetching the groups');
    return null;
  }
}
