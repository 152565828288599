
import { defineComponent } from 'vue';
import * as L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import '@/assets/leaflet/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import { formatTextSimple } from '@/helper/textFormatting';

export default defineComponent({
  name: 'OsmMap',
  props: {
    addressText: {
      type: String,
      required: false,
      default: () => null
    },
    centerX: {
      type: Number,
      required: true
    },
    centerY: {
      type: Number,
      required: true
    },
    showMarker: {
      type: Boolean,
      required: false,
      default: () => true
    },
    scale: {
      type: Number,
      required: false,
      default: () => 13
    }
  },
  computed: {
    id (): string {
      const number = Math.floor(Math.random() * 1398646);
      return `map_${number}`;
    }
  },
  mounted () {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    var map = L.map(this.id).setView([this.centerX, this.centerY], this.scale);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    map.gestureHandling.enable();

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    if (this.addressText == null) {
      L.marker([this.centerX, this.centerY]).addTo(map);
    }

    if (this.addressText != null) {
      L.marker([this.centerX, this.centerY]).addTo(map)
        .bindPopup(formatTextSimple(this.addressText))
        .openPopup();
    }
  }
});
