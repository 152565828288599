

import { defineComponent } from 'vue';
import StartseiteVorstellung from '@/components/Startseite/StartseiteVorstellung.vue';
import StartseiteInfos from '@/components/Startseite/StartseiteInfos.vue';
import StartseiteUeberUns from '@/components/Startseite/StartseiteUeberUns.vue';
import StartseiteGruppen from '@/components/Startseite/StartseiteGruppen.vue';
import StartseiteAnfahrt from '@/components/Startseite/StartseiteAnfahrt.vue';
import StartseiteHeadCarousell from '@/components/Startseite/StartseiteHeadCarousell.vue';
import PageLoader from '@/components/Loading/PageLoader.vue';
import { LoadingRequestedData, LoadingRequestedDataTargets, LoadingState } from '@/api/Loading';
import { loadStartseiteData, StartAboutUsBoxData, StartData } from '@/api/ApiStartseite';
import StartseiteKontakt from '@/components/Startseite/StartseiteKontakt.vue';
import { BaseData } from '@/data_models/BaseData';

export default defineComponent({
  name: 'Startseite',
  components: {
    PageLoader,
    StartseiteHeadCarousell,
    StartseiteInfos,
    StartseiteUeberUns,
    StartseiteVorstellung,
    StartseiteGruppen,
    StartseiteAnfahrt,
    StartseiteKontakt
  },
  data () {
    return {
      loadingDataTargets: [{ target: 'BaseData', ids: null }, { target: 'StartData', ids: null }, { target: 'MainSlides', ids: null }] as LoadingRequestedData,
      internalLoadingState: 'loading' as LoadingState,
      requiredImagesLoaded: false
    };
  },
  computed: {
    aboutUsData (): Array<StartAboutUsBoxData> {
      const data: Array<StartAboutUsBoxData> = [];

      if (BaseData.startData == null) {
        return data;
      }

      data.push({
        title: BaseData.startData.about_us_1_title,
        text: BaseData.startData.about_us_1_text,
        iconUrl: BaseData.startData.about_us_1_icon?.data.full_url ?? ''
      });

      data.push({
        title: BaseData.startData.about_us_2_title,
        text: BaseData.startData.about_us_2_text,
        iconUrl: BaseData.startData.about_us_2_icon?.data.full_url ?? ''
      });

      data.push({
        title: BaseData.startData.about_us_3_title,
        text: BaseData.startData.about_us_3_text,
        iconUrl: BaseData.startData.about_us_3_icon?.data.full_url ?? ''
      });

      return data;
    },
    startData (): StartData | null {
      return BaseData.startData;
    }
  },
  methods: {
    scrollToDates () {
      const infos = this.$refs['ref-infos'] as typeof StartseiteInfos;
      infos.scrollToDates();
    }
  }
});

