import { client } from './Directus';

export interface News {
  id: number;
  // eslint-disable-next-line camelcase
  created_on: string;
  status: string;
  // eslint-disable-next-line camelcase
  relevant_until?: string;
  titel: string;
  // eslint-disable-next-line camelcase
  short_desc?: string;
  description?: string;
  // eslint-disable-next-line camelcase
  modified_on: string // Eigentlich DateTime
}

export async function loadNews (): Promise<Array<News> | null> {
  try {
    const response = await client.getItems<News[]>(
      'neuigkeiten',
      {
        filter: {
          status: { eq: 'published' },
          relevant_until: { gte: new Date().toISOString().split('T')[0] }
        },
        // sort: ['sorting_news', '-created_on']
        sort: 'sorting_news,-created_on'
      }
    );
    return response.data;
  } catch (e) {
    console.warn('Error when fetching the news');
    return null;
  }
}
