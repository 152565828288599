
import { defineComponent, PropType } from 'vue';
import { News } from '@/api/ApiNews';
import { sanitize } from 'dompurify';
import linksFinder from 'links-finder';
import { formatTextSimple } from '@/helper/textFormatting';

export default defineComponent({
  name: 'StartseiteInfoNeuigkeit',
  props: {
    neuigkeit: {
      type: Object as PropType<News>,
      required: true
    },
    showFulltext: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  emits: ['showNews', 'closeNewsDetails'],
  computed: {
    newsText (): string {
      let text = this.showFulltext ? this.neuigkeit.description : this.neuigkeit.short_desc;
      text = text ?? '';
      return formatTextSimple(text, true);
    },
    neuigkeitDate (): string {
      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      };
      const date = new Date(this.neuigkeit.modified_on);
      return date.toLocaleString('de-DE', options);
    }
  },
  methods: {
    showNewsDetail (): void {
      this.$emit('showNews', this.neuigkeit.id);
    },
    closeNewsDetail (): void {
      this.$emit('closeNewsDetails');
    }
  }
});
