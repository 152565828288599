
import { defineComponent } from 'vue';
import StartseiteGroup from '@/components/Startseite/StartseiteGroup.vue';
import { GroupData, loadGroups } from '@/api/ApiGroups';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'StartseiteGruppen',
  components: {
    StartseiteGroup,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data () {
    return {
      gruppen: null as null | Array<GroupData>,
      breakpoints: {
        // 840px and up
        840: {
          itemsToShow: 2,
          snapAlign: 'start'
        },
        // 1200 and up
        1200: {
          itemsToShow: 3,
          snapAlign: 'start'
        }
      }
    };
  },
  created () {
    loadGroups().then((groups: Array<GroupData> | null) => {
      this.gruppen = groups;
    });
  }
});
