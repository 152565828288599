
import { defineComponent, PropType } from 'vue';
import Termin from '@/data_models/Termin';

export default defineComponent({
  name: 'StartseiteInfoTermin',
  props: {
    termin: {
      type: Object as PropType<Termin>,
      required: true
    }
  },
  computed: {
    terminTitle (): string {
      return this.termin.title;
    },
    terminDatumGruppe (): string {
      let gruppe = '';
      if (this.termin.calendar != null) {
        gruppe = ` - ${this.termin.calendar}`;
      }
      return this.terminDatum + gruppe;
    },
    terminDatum (): string {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        // year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      };
      const dateString = this.termin.begin.toLocaleString('de-DE', options);
      return `${dateString} Uhr`;
    }
  }
});
