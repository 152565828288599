export const baseUrlChurchtools = 'https://gemeinschaftamdurer.church.tools';
export const apiBaseUrlChurchtools = 'https://gemeinschaftamdurer.church.tools/api/';
export const calBaseUrlChurchtools = 'https://gemeinschaftamdurer.church.tools/index.php?q=churchcal/ajax';
export const apiBaseUrlDirectus = '';

// Kategorien der Kalender (LKG am Dürer):
// Gottesdienst 2
// Veranstaltungen 1
// Generation plus 27
// Jugend + EC 3
// Pfadfinder PFC 24
// Alpha-Kurs 21
// Buchcafé 38
// Kleingruppen 57
// Bibelkreis 42
// Gebetskreis 45

export const calendarIds = [2, 1, 27, 3, 24, 21, 38, 57, 42, 45];
