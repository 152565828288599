
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageLoadWaiting',
  data () {
    return {
      textIndex: 0 as number
    };
  },
  computed: {
    text (): string {
      if (this.textIndex === 0) { return 'Jesus im Zentrum'; }

      return 'Gemeinschaft am Dürer';
    }
  },
  mounted () {
    setTimeout(this.timeIndex, 2400);
  },
  methods: {
    timeIndex (): void {
      setTimeout(this.timeIndex, 2400);

      if (this.textIndex === 0) {
        this.textIndex = 1;
        return;
      }

      this.textIndex = 0;
    }
  }
});
