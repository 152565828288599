import Termin from '@/data_models/Termin';
import { AppointmentDto } from '@/api/ApiCal';

export function AppointmentZuTermin (appointment: AppointmentDto): Termin {
  const startDate: Date = new Date(Date.parse(appointment.calculated.startDate));
  const endDate: Date = new Date(Date.parse(appointment.calculated.endDate));

  const appointmentData = appointment.base;

  const termin = new Termin(appointmentData.id, appointmentData.caption, startDate, endDate, null, appointmentData.calendar.nameTranslated);
  termin.descriptions.push(appointmentData.information);
  termin.descriptions.push(appointmentData.note);

  return termin;
}
