/* eslint-disable camelcase */
import { client } from '@/api/Directus';
import { DirectusImageObject } from '@/api/DirectusDTOs';

export interface MainSlidesData {
  'id': number,
  'text_main': string,
  'text_additional': string | null,
  'slider_image': DirectusImageObject,
  'title': string,
  'image_credits': null,
  'image_credits_link': null,
  'side_visible_until': string,
  'color_main': string,
  'color_additional': string
}

export async function loadMainSlidesData (): Promise<MainSlidesData[]> {
  const response = await client.getItems<MainSlidesData[]>(
    'main_slides',
    {
      filter: {
        side_visible_until: { gte: new Date().toISOString().split('T')[0] }
      },
      sort: 'created_on',
      fields: ['*', 'slider_image.data.full_url']
    }
  );

  return response.data;
}
