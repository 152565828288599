
import { defineComponent, PropType } from 'vue';
import { StartSlidesData } from '@/api/ApiStartseite';
import { formatPurifyText, formatTextSimple, StringIsNullOrEmpty } from '@/helper/textFormatting';

export default defineComponent({
  name: 'StartseiteHeadCarousellItem',
  props: {
    slideData: {
      type: Object as PropType<StartSlidesData>,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['img-loaded'],
  computed: {
    classes (): string {
      if (this.isActive) {
        return 'carousell-item--active';
      }

      return '';
    },
    textMain (): string {
      return formatTextSimple(this.slideData.text_main);
    },
    textAdditional (): string | null {
      if (StringIsNullOrEmpty(this.slideData.text_additional)) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return formatTextSimple(this.slideData.text_additional!, true);
    },
    styleMain (): string {
      return `color: ${this.slideData.color_main}`;
    },
    styleAdditional (): string {
      return `color: ${this.slideData.color_additional}`;
    }
  },
  methods: {
    imgLoaded (): void {
      console.log(`Slider image loaded "${this.slideData.title}"`);
      this.$emit('img-loaded');
    }
  }
});
