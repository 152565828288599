
import { defineComponent } from 'vue';
import { formatPurifyText } from '@/helper/textFormatting';

export default defineComponent({
  name: 'StartseiteVorstellung',
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    textFormatted (): string {
      return formatPurifyText(this.text);
    }
  }
});

