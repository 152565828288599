import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c73f4952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "startseite" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_startseite_head_carousell = _resolveComponent("startseite-head-carousell")!
  const _component_startseite_vorstellung = _resolveComponent("startseite-vorstellung")!
  const _component_startseite_infos = _resolveComponent("startseite-infos")!
  const _component_startseite_ueber_uns = _resolveComponent("startseite-ueber-uns")!
  const _component_startseite_gruppen = _resolveComponent("startseite-gruppen")!
  const _component_startseite_anfahrt = _resolveComponent("startseite-anfahrt")!
  const _component_startseite_kontakt = _resolveComponent("startseite-kontakt")!
  const _component_PageLoader = _resolveComponent("PageLoader")!

  return (_openBlock(), _createBlock(_component_PageLoader, { "loading-data-targets": _ctx.loadingDataTargets }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_startseite_head_carousell, {
          "greeter-image": _ctx.startData.greeter_image,
          "important-slide": _ctx.startData.important_slide?.id,
          onToTheDates: _ctx.scrollToDates
        }, null, 8, ["greeter-image", "important-slide", "onToTheDates"]),
        _createVNode(_component_startseite_vorstellung, {
          title: _ctx.startData.welcome_title,
          text: _ctx.startData.welcome_text
        }, null, 8, ["title", "text"]),
        _createVNode(_component_startseite_infos, { ref: "ref-infos" }, null, 512),
        _createVNode(_component_startseite_ueber_uns, { "about-us": _ctx.aboutUsData }, null, 8, ["about-us"]),
        _createVNode(_component_startseite_gruppen),
        _createElementVNode("div", null, [
          _createVNode(_component_startseite_anfahrt, {
            address: _ctx.startData.church_address,
            "center-x": _ctx.startData.church_location.lat,
            "center-y": _ctx.startData.church_location.lng
          }, null, 8, ["address", "center-x", "center-y"]),
          _createVNode(_component_startseite_kontakt)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading-data-targets"]))
}