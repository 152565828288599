
import { defineComponent } from 'vue';
import OsmMap from '@/components/Allgemein/OsmMap.vue';

export default defineComponent({
  name: 'StartseiteAnfahrt',
  components: {
    OsmMap
  },
  props: {
    address: {
      type: String,
      required: true
    },
    centerX: {
      type: Number,
      required: true
    },
    centerY: {
      type: Number,
      required: true
    }
  }
});

