/* eslint-disable camelcase */
import { client } from '@/api/Directus';
import { DirectusImageObject } from '@/api/DirectusDTOs';

export interface StartData {
  id: number,
  welcome_title: string,
  welcome_text: string,
  greeter_image: DirectusImageObject | null,
  important_slide: { id: number } | null,
  live_stream_link: string | null,
  live_stream_display_start: string | null, // Eigentlich ein Date
  live_stream_display_end: string | null, // Eigentlich ein Date
  about_us_1_title: string,
  about_us_2_title: string,
  about_us_3_title: string,
  about_us_1_icon: DirectusImageObject | null,
  about_us_2_icon: DirectusImageObject | null,
  about_us_3_icon: DirectusImageObject | null,
  about_us_1_text: string,
  about_us_2_text: string,
  about_us_3_text: string,
  church_address: string,
  church_location:
    {
      lat: number
      lng: number
    } | null,
}

export interface StartSlidesData {
  id: number,
  side_visible_until: string | null,
  title: string,
  text_main: string,
  text_additional: string | null,
  color_main: string,
  color_additional: string
  slider_image: DirectusImageObject | null,
  image_credits: string | null,
  image_credits_link: string | null,
}

export interface StartAboutUsBoxData {
  title: string,
  text: string,
  iconUrl: string
}

export async function loadStartseiteData (): Promise<StartData> {
  const response = await client.getItem<StartData>(
    'start',
    1,
    {
      fields: ['*', 'greeter_image.data.full_url', 'about_us_1_icon.data.full_url', 'about_us_2_icon.data.full_url', 'about_us_3_icon.data.full_url']
    }
  );

  return response.data;
}

export async function loadStartseiteSlidesData (excludedSlideId: number | null = null): Promise<Array<StartSlidesData>> {
  const filters: Record<string, unknown> = { side_visible_until: { gte: new Date().toISOString().split('T')[0] } };
  if (excludedSlideId != null) {
    filters.id = { neq: excludedSlideId };
  }

  const response = await client.getItems<Array<StartSlidesData>>(
    'main_slides',
    {
      fields: ['*'],
      sort: 'sort',
      filter: {
        side_visible_until: { gte: new Date().toISOString().split('T')[0] },
        id: { neq: excludedSlideId }
      },
      limit: 5
    }
  );

  return response.data;
}

export async function loadStartseiteSlideData (id: number): Promise<StartSlidesData> {
  const response = await client.getItem<StartSlidesData>(
    'main_slides',
    id,
    {
      fields: ['*']
    }
  );

  return response.data;
}
