
import { defineComponent } from 'vue';
import Termin from '@/data_models/Termin';
import StartseiteInfoTermin from '@/components/Startseite/StartseiteInfoTermin.vue';
import StartseiteInfoNeuigkeit from '@/components/Startseite/StartseiteInfoNeuigkeit.vue';
import { loadNews, News } from '@/api/ApiNews';
import { apiBaseUrlChurchtools, calendarIds } from '@/other/config';
import { token } from '@/other/token';
import { getAppointments } from '@/api/ApiCal';
import { AppointmentZuTermin } from '@/data_helper/TerminHelper';

export default defineComponent({
  name: 'StartseiteInfos',
  components: {
    StartseiteInfoNeuigkeit,
    StartseiteInfoTermin
  },
  data () {
    return {
      neuigkeiten: [] as News[],
      visibleNewsDetails: null as News | null,
      termine: [] as Termin[]
    };
  },
  async created () {
    const today: Date = new Date(Date.now());
    const sixWeeks: Date = new Date(Date.now());
    sixWeeks.setDate(sixWeeks.getDate() + 92);

    let appointments = await getAppointments({
      baseUrl: apiBaseUrlChurchtools,
      authToken: token,
      startDate: today,
      endDate: sixWeeks,
      calendarIds: calendarIds
    });

    if (appointments == null) { appointments = []; }
    for (const appointment of appointments) {
      const termin = AppointmentZuTermin(appointment);
      this.termine.push(termin);
    }

    // getEvents({
    //   baseUrl: apiBaseUrlChurchtools,
    //   authToken: token,
    //   startDate: today,
    //   endDate: sixWeeks
    // }).then((termine) => {
    //   if (termine != null) {
    //     this.termine = this.termine.concat(termine);
    //   }
    // });

    loadNews()
      .then((news) => {
        if (news != null) {
          this.neuigkeiten = news;
        }
      });
  },
  methods: {
    scrollToDates (): void {
      const datesElement = this.$refs['ref-dates'] as HTMLElement;
      datesElement.scrollIntoView({ behavior: 'smooth' });
    },
    showNewsDetails (newsId: number): void {
      this.visibleNewsDetails = this.neuigkeiten.find((x) => x.id === newsId) ?? null;
    },
    hideNewsDetails (): void {
      this.visibleNewsDetails = null;
    }
  }
});
