
import { defineComponent, PropType } from 'vue';
import Tabs from '@/components/Allgemein/tabs.vue';
import { GroupData } from '@/api/ApiGroups';
import { formatPurifyText } from '@/helper/textFormatting';
import OsmMap from '@/components/Allgemein/OsmMap.vue';

export default defineComponent({
  name: 'StartseiteGroup',
  components: { Tabs, OsmMap },
  props: {
    groupData: {
      type: Object as PropType<GroupData>,
      required: true
    }
  },
  data () {
    const tabs = ['Basic', 'Wann & Wo'];
    return {
      tabs,
      selectedTab: tabs[0],
      displayMap: false
    };
  },
  computed: {
    groupSlogan (): string {
      return formatPurifyText(this.groupData.group_slogan);
    },
    groupDescription (): string {
      return formatPurifyText(this.groupData.group_short_desc, true);
    },
    hasWhenWhere (): boolean {
      return this.groupData.group_meeting_when != null || this.hasWhere;
    },
    hasWhere ():boolean {
      return this.groupData.group_where != null && this.groupData.group_where.lat != null;
    },
    groupWhen (): string {
      if (this.groupData.group_meeting_when == null) {
        return '';
      }
      return formatPurifyText(this.groupData.group_meeting_when, true);
    }
  },
  watch: {
    selectedTab: function (newVal: string) {
      if (newVal === this.tabs[1]) {
        setTimeout(() => { this.displayMap = true; }, 200);
      } else {
        this.displayMap = false;
      }
    }
  }
});
