import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53cc6d18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "tile tile-centered" }
const _hoisted_6 = {
  key: 0,
  class: "tile-icon"
}
const _hoisted_7 = {
  class: "avatar avatar-xl",
  "data-initial": ""
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "tile-content" }
const _hoisted_10 = { class: "tile-title text-left" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 1,
  class: "info-where-when"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "groupWhere"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs = _resolveComponent("tabs")!
  const _component_OsmMap = _resolveComponent("OsmMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.groupData.group_header_image.data.full_url,
        class: "img-fit-cover"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.groupData.group_icon != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("figure", _hoisted_7, [
                _createElementVNode("img", {
                  src: _ctx.groupData.group_icon.data.full_url,
                  alt: "Logo",
                  class: "img-fit-contain"
                }, null, 8, _hoisted_8)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.groupData.group_name), 1),
          _createElementVNode("small", {
            class: "tile-subtitle text-gray text-left",
            innerHTML: _ctx.groupSlogan
          }, null, 8, _hoisted_11)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.hasWhenWhere)
        ? (_openBlock(), _createBlock(_component_tabs, {
            key: 0,
            "active-tab": _ctx.selectedTab,
            "onUpdate:activeTab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
            items: ['Basic', 'Wann & Wo']
          }, null, 8, ["active-tab"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", {
        class: "info-main",
        innerHTML: _ctx.groupDescription
      }, null, 8, _hoisted_13), [
        [_vShow, _ctx.selectedTab === _ctx.tabs[0]]
      ]),
      (_ctx.hasWhenWhere)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_ctx.groupWhen.length > 0)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  innerHTML: _ctx.groupWhen
                }, null, 8, _hoisted_15))
              : _createCommentVNode("", true),
            (_ctx.hasWhere)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  (_ctx.displayMap)
                    ? (_openBlock(), _createBlock(_component_OsmMap, {
                        key: 0,
                        "address-text": _ctx.groupData.group_where_address,
                        "center-x": _ctx.groupData.group_where.lat,
                        "center-y": _ctx.groupData.group_where.lng,
                        scale: 14
                      }, null, 8, ["address-text", "center-x", "center-y"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 512)), [
            [_vShow, _ctx.selectedTab === _ctx.tabs[1]]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}