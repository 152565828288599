import { sanitize } from 'dompurify';
import linksFinder from 'links-finder';

export function formatPurifyText (unformattedText: string, enableLinks = false): string {
  let text = '<p>' + unformattedText.replaceAll('\n\n', '</p><p>') + '</p>';
  text = text.replaceAll('\n', '<br>');

  if (enableLinks) {
    text = convertLinks(text);
    console.log(text);
  }

  // return sanitize(text, { ADD_TAGS: ['p', 'br'] });
  return sanitize(text, { ADD_ATTR: ['target'] });
}

export function formatTextSimple (unformattedText: string, enableLinks = false): string {
  let text = unformattedText;
  if (enableLinks) {
    text = convertLinks(text);
  }

  text = text.replaceAll('\n', '<br>');

  return sanitize(text, { ADD_ATTR: ['target'] });
}

export function StringIsNullOrEmpty (s: string | null): boolean {
  return s == null || s.length <= 0;
}

function convertLinks (text: string, useNewTab = true): string {
  let target = '';
  if (useNewTab) {
    target = 'target="_blank"';
  }

  const textWithLinks = linksFinder.wrapLinks(
    text,
    {
      onMatch: (link: string) => `<a href="${link}" ${target}>${link}</a>`
    }
  );
  return textWithLinks;
}
